<template>
  <div :id="elementId" />
</template>

<style>
  .aa-SubmitIcon {
   display: none;
 }
</style>

<script lang="jsx">
import {Fragment, render} from 'vue';
import {autocomplete, getAlgoliaResults} from '@algolia/autocomplete-js';
import {createElement} from "./utils/createElement.js";
import algoliasearch from 'algoliasearch/lite';
import ProductItem from "./ProductItem.vue";
import OtherItem from "./OtherItem.vue";

const searchClient = algoliasearch(
    'LLXYV3D5EU',
    '84a8be75b35e6b3ea517827e08a97d33'
);

export default {
  name: 'Autocomplete',
  props: {
    elementId: {
      type: String,
    },
  },
  mounted() {
    let nbBlogHits = 0;
    let nbProductHits = 0;
    autocomplete({
      container: '#' + this.elementId,
      placeholder: 'Search products, information, articles and blogs...',
      classNames: {
        form: 'search-form',
        input: 'search-input tw-bg-transparent',
        inputWrapper: 'algolia-autocomplete',
        inputWrapperPrefix: 'tw-rounded-full tw-text-chards-purple tw-bg-white tw-min-w-0 tw-h-12 tw-p-0 tw-order-1',
        inputWrapperSuffix: 'tw-hidden',
        item: 'aa-suggestion',
        label: 'tw-h-full tw-w-12 tw-flex tw-items-center tw-justify-center',
        list: 'aa-suggestions',
        panel: 'aa-dropdown-menu tw-bg-white tw-absolute tw-w-full tw-border md:tw--ml-[120px] md:tw-min-w-[800px] lg:tw-min-w-[900px]',
        source: 'tw-h-full',
        submitButton: 'icon-search tw-text-center tw-text-xl tw-my-3.5 tw-w-12 tw-mw-12',
        submitIcon: 'tw-hidden'
      },
      getSources({ query }) {
        return [
          {
            sourceId: 'products',
            getItems() {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: 'chards_products',
                    query,
                    params: {
                      hitsPerPage: 8,
                      attributesToSnippet: ['name:10', 'description:35'],
                      snippetEllipsisText: '…',
                    },
                  },
                ],
                transformResponse({ results, hits }) {
                  nbProductHits = results[0].nbHits;
                  return hits;
                },
              });
            },
            templates: {
              header() {
                return (<div className="aa-header">Products:</div>);
              },
              item({ item, components }) {
                return (
                    <ProductItem item={item} highlight={components.Highlight} />
                );
              },
              footer(state) {
                return (
                    <div className="aa-footer"><a href={'/products?search=' + state.state.query}>View all {nbProductHits} Search Results for <strong>{state.state.query}</strong> &rsaquo;</a></div>);
              },
            },
          },
          {
            sourceId: 'blogs',
            getItems() {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: 'chards_blogs',
                    query,
                    params: {
                      hitsPerPage: 4,
                      attributesToSnippet: ['name:10', 'description:35'],
                      snippetEllipsisText: '…',
                    },
                  },
                ],
                transformResponse({ results, hits }) {
                  nbBlogHits = results[0].nbHits;
                  return hits;
                },
              });
            },
            // ...
            templates: {
              header() {
                return (<div className="aa-header">Other:</div>);
              },
              footer(state) {
                return (
                    <div className="aa-footer"><a href={'/guides?search=' + state.state.query}>View all {nbBlogHits} Search Results for <strong>{state.state.query}</strong> &rsaquo;</a></div>);
              },
              item({ item, components }) {
                return (
                    <OtherItem item={item} highlight={components.Highlight} />
                );
              },
            },
          },
        ];
      },
      onStateChange({ state }) {
        document.getElementById('js-search-underlay').style.display = (state.isOpen ? 'block' : 'none')
      },
      onSubmit({ state }) {
        window.location.href = '/products?search=' + encodeURIComponent(state.query);
      },
      detachedMediaQuery: 'none',
      renderer: {
        createElement,
        Fragment,
        render,
      },
      render({ elements, render, html }, root) {
        const { blogs, products } = elements;
        let productHtml = '';
        let blogHtml = '';
        if (products) {
          productHtml = html`<div class="col-products"><div class="aa-dataset-products">${products}</div></div>`
        }
        if (blogs) {
          blogHtml = html`<div class="col-blogs"><div class="aa-dataset-blogs">${blogs}</div></div>`
        }
        render(
            html`<div class="custom-menu">
                    <div class="row-results">
                      ${productHtml}
                      ${blogHtml}
                    </div>
                  </div>`,
            root
        );
      },
    });
  },
}
</script>
