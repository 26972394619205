<template>
    <a
        :href="item.url"
        class="aa-ItemWrapper"
    >
        <div
            class="tw-grid tw-grid-cols-3"
            style="white-space: normal;"
        >
            <div>
                <img
                    :src="item.image_url"
                    :alt="item.name"
                >
            </div>
            <div class="tw-col-span-2">
                <div class="product-title"><component
                    :is="highlight"
                    :hit="item"
                    attribute="name"
                /></div>
                <!-- eslint-disable vue/no-v-html-->
                <div
                    v-if="!item.stock_list"
                    class="product-price-wrap"
                    v-html="item.price_from"
                />
                <!-- eslint-enable vue/no-v-html-->
            </div>
        </div>
    </a>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        highlight: {
            type: Function,
            required: true
        }
    },
    setup() {
        function onAddToCart(event) {
            event.preventDefault();
            event.stopPropagation();
        }

        return {
            onAddToCart
        };
    }
};
</script>
